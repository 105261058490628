<template>
    <div>
      <!--        创建订单-->
      <v-dialog v-model="showAddOrder" persistent max-width="700px">
        <OrderPosition @cancel-select="cancelSelectPosition"></OrderPosition>
      </v-dialog>
        <v-row>
            <v-col cols="6">
                <v-menu v-model="menu" :close-on-content-click="false" :nudge-right="40" transition="scale-transition"
                    offset-y min-width="auto">
                    <template v-slot:activator="{ on, attrs }">
                        <v-text-field v-model="date" label="报表日期" prepend-icon="mdi-calendar" readonly v-bind="attrs"
                            v-on="on"></v-text-field>
                    </template>
                    <v-date-picker v-model="date" @input="menu = false" :first-day-of-week="1" locale="zh-cn"
                        color="primary"></v-date-picker>
                </v-menu>
            </v-col>
            <v-col cols="6">
              <v-row>
                <v-col cols="9">

                </v-col>
                <v-col cols="3">
                  <div class='d-flex align-center flex-wrap'>
                    <v-btn class='mb-4 me-3' color='primary'  @click="showAddOrder = true">
                      <v-icon>{{ icons.mdiPlus }}</v-icon>
                      <span>创建订单</span>
                    </v-btn>
                  </div>
                </v-col>
              </v-row>
            </v-col>
            <v-col @click="toPage('/apps/orders/complain/list')" class="align-self-end" cols="12" md="3" order="1" sm="6">
                <statistics-card-with-images :avatar="ratingsOptions.avatar" :avatar-width="ratingsOptions.avatarWidth"
                    :chip-color="ratingsOptions.chipColor" :chip-text="date" :stat-title="'未处理投诉建议'"
                    :statistics="data.other.unread_complain"></statistics-card-with-images>
            </v-col>
            <v-col class="align-self-end" cols="12" md="3" order="2" sm="6">
                <statistics-card-with-images :avatar="sessionsOptions.avatar" :avatar-width="sessionsOptions.avatarWidth"
                    :chip-color="sessionsOptions.chipColor" :chip-text="date" :stat-title="'在线车辆'"
                    :statistics="data.other.online_vehicle"></statistics-card-with-images>
            </v-col>
            <v-col cols="12" md="6" order="5" order-md="3" sm="12">
                <v-card @click="toPage('/apps/orders/order/all')">
                    <v-card-title class="align-start"><span class="font-weight-semibold">订单相关</span>
                        <v-spacer></v-spacer>
                    </v-card-title>
                    <v-card-subtitle class="mb-7"><span class="font-weight-semibold text--primary me-1">包含未完成订单</span>
                        <span>{{ date }}</span></v-card-subtitle>
                    <v-card-text>
                        <v-row>
                            <v-col class="d-flex align-center" cols="6" sm="4">
                                <v-avatar color="primary" class="elevation-1" rounded size="44">
                                    <v-icon color="white" dark size="30"> {{ icons.mdiTrendingUp }}</v-icon>
                                </v-avatar>
                                <div class="ms-3">
                                    <p class="text-xs mb-0"> 总订单数</p>
                                    <h3 class="text-xl font-weight-bold"> {{ data.order.total }} </h3>
                                </div>
                            </v-col>
                            <v-col class="d-flex align-center" cols="6" sm="4">
                                <v-avatar color="success" class="elevation-1" rounded size="44">
                                    <v-icon color="white" dark size="30"> {{ icons.mdiCalendarToday }}</v-icon>
                                </v-avatar>
                                <div class="ms-3">
                                    <p class="text-xs mb-0"> 今日新增</p>
                                    <h3 class="text-xl font-weight-bold"> {{ data.order.today }} </h3>
                                </div>
                            </v-col>
                            <v-col class="d-flex align-center" cols="6" sm="4">
                                <v-avatar color="info" class="elevation-1" rounded size="44">
                                    <v-icon color="white" dark size="30"> {{ icons.mdiCalendarMonth }}</v-icon>
                                </v-avatar>
                                <div class="ms-3">
                                    <p class="text-xs mb-0"> 本月新增</p>
                                    <h3 class="text-xl font-weight-bold"> {{ data.order.month }} </h3>
                                </div>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12" md="6" order="5" order-md="3" sm="12">
                <v-card @click="toPage('/apps/users/wechat/user')">
                    <v-card-title class="align-start"><span class="font-weight-semibold">乘客用户相关</span>
                        <v-spacer></v-spacer>
                    </v-card-title>
                    <v-card-subtitle class="mb-7"><span
                            class="font-weight-semibold text--primary me-1">所有注册了乘客端小程序的用户</span>
                        <span>{{ date }}</span></v-card-subtitle>
                    <v-card-text>
                        <v-row>
                            <v-col class="d-flex align-center" cols="6" sm="4">
                                <v-avatar color="primary" class="elevation-1" rounded size="44">
                                    <v-icon color="white" dark size="30"> {{ icons.mdiTrendingUp }}</v-icon>
                                </v-avatar>
                                <div class="ms-3">
                                    <p class="text-xs mb-0"> 总用户数</p>
                                    <h3 class="text-xl font-weight-bold"> {{ data.passenger.total }} </h3>
                                </div>
                            </v-col>
                            <v-col class="d-flex align-center" cols="6" sm="4">
                                <v-avatar color="success" class="elevation-1" rounded size="44">
                                    <v-icon color="white" dark size="30"> {{ icons.mdiCalendarToday }}</v-icon>
                                </v-avatar>
                                <div class="ms-3">
                                    <p class="text-xs mb-0"> 今日新增</p>
                                    <h3 class="text-xl font-weight-bold"> {{ data.passenger.today }} </h3>
                                </div>
                            </v-col>
                            <v-col class="d-flex align-center" cols="6" sm="4">
                                <v-avatar color="info" class="elevation-1" rounded size="44">
                                    <v-icon color="white" dark size="30"> {{ icons.mdiCalendarMonth }}</v-icon>
                                </v-avatar>
                                <div class="ms-3">
                                    <p class="text-xs mb-0"> 本月新增</p>
                                    <h3 class="text-xl font-weight-bold"> {{ data.passenger.month }} </h3>
                                </div>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-col>
            <v-col cols="12" md="6" order="5" order-md="3" sm="12">
                <v-card @click="toPage('/apps/users/passenger/list')">
                    <v-card-title class="align-start"><span class="font-weight-semibold">会员用户相关</span>
                        <v-spacer></v-spacer>
                    </v-card-title>
                    <v-card-subtitle class="mb-7"><span
                            class="font-weight-semibold text--primary me-1">注册了乘客端小程序并实名认证的用户</span>
                        <span>{{ date }}</span></v-card-subtitle>
                    <v-card-text>
                        <v-row>
                            <v-col class="d-flex align-center" cols="6" sm="4">
                                <v-avatar color="primary" class="elevation-1" rounded size="44">
                                    <v-icon color="white" dark size="30"> {{ icons.mdiTrendingUp }}</v-icon>
                                </v-avatar>
                                <div class="ms-3">
                                    <p class="text-xs mb-0"> 总会员数</p>
                                    <h3 class="text-xl font-weight-bold"> {{ data.passenger.real_total }} </h3>
                                </div>
                            </v-col>
                            <v-col class="d-flex align-center" cols="6" sm="4">
                                <v-avatar color="success" class="elevation-1" rounded size="44">
                                    <v-icon color="white" dark size="30"> {{ icons.mdiCalendarToday }}</v-icon>
                                </v-avatar>
                                <div class="ms-3">
                                    <p class="text-xs mb-0"> 今日新增</p>
                                    <h3 class="text-xl font-weight-bold"> {{ data.passenger.real_today }} </h3>
                                </div>
                            </v-col>
                            <v-col class="d-flex align-center" cols="6" sm="4">
                                <v-avatar color="info" class="elevation-1" rounded size="44">
                                    <v-icon color="white" dark size="30"> {{ icons.mdiCalendarMonth }}</v-icon>
                                </v-avatar>
                                <div class="ms-3">
                                    <p class="text-xs mb-0"> 本月新增</p>
                                    <h3 class="text-xl font-weight-bold"> {{ data.passenger.real_month }} </h3>
                                </div>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12" md="6" order="5" order-md="3" sm="12">
                <v-card @click="toPage('/apps/users/wechat/user')">
                    <v-card-title class="align-start"><span class="font-weight-semibold">司机用户相关</span>
                        <v-spacer></v-spacer>
                    </v-card-title>
                    <v-card-subtitle class="mb-7"><span
                            class="font-weight-semibold text--primary me-1">所有注册了司机端小程序的用户</span>
                        <span>{{ date }}</span></v-card-subtitle>
                    <v-card-text>
                        <v-row>
                            <v-col class="d-flex align-center" cols="6" sm="4">
                                <v-avatar color="primary" class="elevation-1" rounded size="44">
                                    <v-icon color="white" dark size="30"> {{ icons.mdiTrendingUp }}</v-icon>
                                </v-avatar>
                                <div class="ms-3">
                                    <p class="text-xs mb-0"> 总用户数</p>
                                    <h3 class="text-xl font-weight-bold"> {{ data.driver.total }} </h3>
                                </div>
                            </v-col>
                            <v-col class="d-flex align-center" cols="6" sm="4">
                                <v-avatar color="success" class="elevation-1" rounded size="44">
                                    <v-icon color="white" dark size="30"> {{ icons.mdiCalendarToday }}</v-icon>
                                </v-avatar>
                                <div class="ms-3">
                                    <p class="text-xs mb-0"> 今日新增</p>
                                    <h3 class="text-xl font-weight-bold"> {{ data.driver.today }} </h3>
                                </div>
                            </v-col>
                            <v-col class="d-flex align-center" cols="6" sm="4">
                                <v-avatar color="info" class="elevation-1" rounded size="44">
                                    <v-icon color="white" dark size="30"> {{ icons.mdiCalendarMonth }}</v-icon>
                                </v-avatar>
                                <div class="ms-3">
                                    <p class="text-xs mb-0"> 本月新增</p>
                                    <h3 class="text-xl font-weight-bold"> {{ data.driver.month }} </h3>
                                </div>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-col>
            <v-col cols="12" md="4" order="5" order-md="3" sm="12">
                <v-card @click="toPage('/apps/users/driver/list')">
                    <v-card-title class="align-start"><span class="font-weight-semibold">认证司机相关</span>
                        <v-spacer></v-spacer>
                    </v-card-title>
                    <v-card-subtitle class="mb-7"><span
                            class="font-weight-semibold text--primary me-1">注册了司机端小程序并实名认证的用户</span>
                    </v-card-subtitle>
                    <v-card-text>
                        <v-row>
                            <v-col class="d-flex align-center" cols="6" sm="6">
                                <v-avatar color="primary" class="elevation-1" rounded size="44">
                                    <v-icon color="white" dark size="30"> {{ icons.mdiTrendingUp }}</v-icon>
                                </v-avatar>
                                <div class="ms-3">
                                    <p class="text-xs mb-0"> 总司机数</p>
                                    <h3 class="text-xl font-weight-bold"> {{ data.driver.real_total }} </h3>
                                </div>
                            </v-col>
                            <!-- <v-col class="d-flex align-center" cols="6" sm="4">
                                <v-avatar color="success" class="elevation-1" rounded size="44">
                                    <v-icon color="white" dark size="30"> {{ icons.mdiCalendarToday }}</v-icon>
                                </v-avatar>
                                <div class="ms-3">
                                    <p class="text-xs mb-0"> 今日新增</p>
                                    <h3 class="text-xl font-weight-bold"> {{ data.driver.real_today }} </h3>
                                </div>
                            </v-col> -->
                            <v-col class="d-flex align-center" cols="6" sm="6">
                                <v-avatar color="info" class="elevation-1" rounded size="44">
                                    <v-icon color="white" dark size="30"> {{ icons.mdiCalendarMonth }}</v-icon>
                                </v-avatar>
                                <div class="ms-3">
                                    <p class="text-xs mb-0"> 本月新增</p>
                                    <h3 class="text-xl font-weight-bold"> {{ data.driver.real_month }} </h3>
                                </div>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-col>
            <v-col cols="12" md="2" order="5" order-md="3" sm="12">
                <v-card @click="toPage('/apps/users/driver/real/list')">
                    <v-card-title class="align-start"><span class="font-weight-semibold">待审核</span>
                        <v-spacer></v-spacer>
                    </v-card-title>
                    <v-card-subtitle class="mb-7"><span class="font-weight-semibold text--primary me-1">待审核司机</span>
                    </v-card-subtitle>
                    <v-card-text>
                        <v-row>
                            <v-col class="d-flex align-center" cols="12" sm="12">
                                <v-avatar color="warning" class="elevation-1" rounded size="44">
                                    <v-icon color="white" dark size="30"> {{ icons.mdiAccountCheckOutline }}</v-icon>
                                </v-avatar>
                                <div class="ms-3">
                                    <p class="text-xs mb-0"> 待审核</p>
                                    <h3 class="text-xl font-weight-bold"> {{ data.driver.wait_accept }} </h3>
                                </div>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12" sm="12">
                <v-card>
                    <v-card-title class="align-start"><span>近期订单</span>
                        <v-spacer></v-spacer>
                    </v-card-title>
                    <v-card-text>
                        <v-timeline class="timeline-custom-dense timeline-custom-dots" dense>
                            <v-timeline-item v-for="(item, i) in (data.latest_orders || [])" :key="i"
                                :color="enums.statusColor[item.state]" small>
                                <div class="d-flex justify-space-between align-center flex-wrap">
                                    <h4 class="font-weight-semibold me-1"> {{ `[${item.order_type}]
                                                                            [${enums.status[item.state]}]
                                                                            [车牌号：${item.vehicle_no ? item.vehicle_no : '-'}]
                                                                            预计[${(item.evaluate_distance / 1000).toFixed(1)}公里]
                                                                            [${item.evaluate_price}元]
                                                                            [${item.estimated_arrive_at}到达]` }}</h4>
                                    <small class="text-no-wrap">{{ item.create_at }}</small>
                                </div>
                                <p class="mb-0"> {{ `从
                                    ${item.start_address}(${item.start_lng},${item.start_lat}) 出发，到
                                                                    ${item.end_address}(${item.end_lng},${item.end_lat}) ` }} </p>
                            </v-timeline-item>
                        </v-timeline>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
        <v-overlay :value="loading">
            <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>
    </div>
</template>
<script>
import StatisticsCardWithImages from '@core/components/statistics-card/StatisticsCardWithImages.vue'
import StatisticsCardAreaChart from '@core/components/statistics-card/StatisticsCardAreaChart.vue'
import StatisticsCardVertical from '@/@core/components/statistics-card/StatisticsCardVertical.vue'
// icons
import { mdiLabelVariantOutline } from '@mdi/js'
// demos
import CrmStatisticsCard from './CrmStatisticsCard.vue'
import CrmTotalSales from './CrmTotalSales.vue'
import CrmRevenueReport from './CrmRevenueReport.vue'
import CrmSalesOverview from './CrmSalesOverview.vue'
import CrmActivityTimeline from './CrmActivityTimeline.vue'
import CrmWeeklySales from './CrmWeeklySales.vue'
import CrmCardUpgradePlan from './CrmCardUpgradePlan.vue'
import CrmCardMeetingSchedule from './CrmCardMeetingSchedule.vue'
import CrmCardDeveloperMeetup from './CrmCardDeveloperMeetup.vue'
import axios from '@axios'
import { mdiAccountOutline, mdiDotsVertical, mdiLabelOutline, mdiTrendingUp, mdiCalendarToday, mdiCalendarMonth, mdiAccountCheckOutline } from '@mdi/js'
import { ref, watch } from '@vue/composition-api'
import { useRouter } from '@core/utils'
import OrderPosition from "@/views/components/order-position/OrderPosition.vue";

export default {
    components: {
        OrderPosition,
        StatisticsCardWithImages,
        StatisticsCardAreaChart,
        StatisticsCardVertical,
        CrmStatisticsCard,
        CrmTotalSales,
        CrmRevenueReport,
        CrmSalesOverview,
        CrmActivityTimeline,
        CrmWeeklySales,
        CrmCardUpgradePlan,
        CrmCardMeetingSchedule,
        CrmCardDeveloperMeetup,
    }, setup() {
        // card with images
        const ratingsOptions = { statTitle: 'Ratings', statistics: '13k', change: '+38%', chipText: 'Year of 2021', chipColor: 'primary', avatar: require('@/assets/images/avatars/9.png'), avatarWidth: '111', }
        const sessionsOptions = { statTitle: 'Sessions', statistics: '24.5k', change: '-22%', chipText: 'Last Week', chipColor: 'secondary', avatar: require('@/assets/images/avatars/10.png'), avatarWidth: '86', }
        const growthAreaChart = { statTitle: 'Total Growth', statistics: '42.5k', series: [{ name: 'Subscribers', data: [28, 40, 36, 52, 38, 60, 55], },], }
        // vertical card options
        const newProjectOptions = { statTitle: 'New Project', icon: mdiLabelVariantOutline, color: 'primary', subtitle: 'Yearly Project', statistics: '862', change: '-18%', }

        const enums = {
            // // 1=订单完成 11=用户取消 12=超时自动取消 96=送达乘客 97=接到乘客 98=司机接单 99=提交订单（司机看不到99状态）
            status: { 1: "已完成", 2: "订单超时取消", 11: "用户取消", 12: "司机取消", 96: "已送达", 97: "接到乘客", 98: "已接单", 99: "待接单" },
            statusColor: { 1: "success", 2: "error",  11: "error", 12: "error", 96: "primary", 97: "secondary", 98: "info", 99: "warning" },
        }

        const date = ref('')
        const menu = ref(false)
        const loading = ref('false')
        const showAddOrder = ref(false)
        const data = ref({
            dialogAddOrder: {
              show: false
            },
            order: {
                total: 0,
                today: 0,
                month: 0,
            },
            passenger: {
                total: 0,
                today: 0,
                month: 0,
                real_total: 0,
                real_today: 0,
                real_month: 0,
            },
            driver: {
                total: 0,
                today: 0,
                month: 0,
                real_total: 0,
                real_today: 0,
                real_month: 0,
                wait_accept: 0
            },
            other: {
                online_vehicle: 0,
                unread_complain: 0
            },
            latest_orders: []
        })

        const fetchReportAll = () => {
            // adminReport
            loading.value = true
            axios.get("/admin-company/v1/report/all", { params: { date: date.value } }).then(res => {
                const { code } = res.data
                if (code == 200) {
                    data.value = res.data.data
                }
            }).catch(error => {
            }).finally(loading.value = false)
        }

        const getCurrentTime = () => {
            var date = new Date();//当前时间
            var month = zeroFill(date.getMonth() + 1);//月
            var day = zeroFill(date.getDate());//日
            var curTime = date.getFullYear() + "-" + month + "-" + day
            return curTime;
        }

        const cancelSelectPosition = () => {
          showAddOrder.value = false
        }

        function zeroFill(i) {
            return i >= 0 && i <= 9 ? "0" + i : i
        }

        const { router } = useRouter()
        const toPage = (path) => {
            router.push(path)
        }

        date.value = getCurrentTime()
        fetchReportAll()

        watch([date], () => {
            fetchReportAll()
        }, { deep: true })

        return {
            date, menu, data, enums, loading, ratingsOptions, sessionsOptions, growthAreaChart, newProjectOptions, showAddOrder, toPage, cancelSelectPosition,
            icons: { mdiAccountOutline, mdiDotsVertical, mdiLabelOutline, mdiTrendingUp, mdiCalendarToday, mdiCalendarMonth, mdiAccountCheckOutline },
        }

    },
} </script>
